import { qs } from './../utils';

var $ = require('jquery');

if (qs('#signupForm')) {
    const signupForm = $('#signupForm');
    const signupButton = $('.btn-signup');
    // const surveyAnswers = {};
    let coreid = 5;
    let userChoices = [];

    const PARSLEY_CONFIG_SIGNUP = {
        errorClass: 'has-error',
        successClass: 'has-success',
        classHandler: function(el) {
            return el.$element;
        },
        errorsWrapper: "<span class='help-block'></span>",
        errorTemplate: '<span></span>',
    };

    // SETS QueryParams Data in Local storage and in Form inputs
    // if QueryParams is null sets const value in forms inputs
    const setQueryData = function(queryParams) {
        if (queryParams !== null) {
            const routeQuery = window.atob(queryParams).match(/.{1,2}/g);

            coreid = routeQuery[0][1];
            userChoices = routeQuery.splice(1, routeQuery.length);
            $('#coreId').val(coreid);
            $('#userChoices').val(JSON.stringify(userChoices));
            return;
        }
        $('#coreId').val(coreid);
        $('#userChoices').val(JSON.stringify(userChoices));
    };

    // CHECK IF Query Name Exists in location.Search
    // returns strings
    const getUrlQueryParams = function(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null
            ? ''
            : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    // READY?
    $(document).ready(function() {
        // Apply validation to sign up form
        signupForm.parsley(PARSLEY_CONFIG_SIGNUP);

        // Validation function
        signupForm.submit(function() {
            //$(this).parsley();
            signupForm.parsley();
            signupButton.prop('disabled', true).button('loading');
            return true;
        });

        const queryP = getUrlQueryParams('p');

        //get router params query if exist
        // example: http://localhost:8000/onboarding/signup/?p=YzYxYTJiM2M0YQ==
        // @todo: andrebbe gestito anche in localstorage?
        // la stringa dovrebbe essere almeno di 16 , ?p= escluso
        if (queryP.length >= 16) {
            localStorage.setItem('queryP', queryP);
            // console.log(queryP);
            setQueryData(queryP);
        }

        if (queryP.length < 1) {
            const storedP = localStorage.getItem('queryP');
            setQueryData(storedP);
            // console.log(storedP);
        }
    });
} // END CHECK signupForm
