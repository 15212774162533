import { qs } from './../utils';

var $ = require('jquery');

if (qs('#loginForm')) {
    const loginForm = $('#loginForm');
    const loginInputName = $('#loginForm #name');
    const loginButton = $('.btn-login');
    const loginPwdLost = $('.pwd-lost');

    const PARSLEY_CONFIG_LOGIN = {
        successClass: 'has-success',
        errorClass: 'has-error',
        classHandler: function(el) {
            return el.$element.parent();
        },
        errorsContainer: function(el) {
            return el.$element.closest('.col-md-6');
        },
        errorsWrapper: "<span class='help-block'></span>",
        errorTemplate: "<span style='color: #e74c3c;'></span>",
    };

    // Setup ajax token
    const setupAjaxToken = function() {
        $.ajaxSetup({
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-CSRFToken', DATA_LOGIN.csrf_token);
            },
        });
    };

    // Dealy the execution of a function
    const delay = (function() {
        var timer = 0;
        return function(callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    // Check if the user email match a Euclidea or Wealth Management user
    const checkIfIsWealthManagement = function() {
        setupAjaxToken();
        const email = loginInputName.val();
        if (email) {
            $.ajax({
                url: DATA_LOGIN.wmc_url,
                type: 'POST',
                data: {
                    email: email,
                },
                dataType: 'json',
                async: false,
                success: function(xml) {
                    if (xml.customer_type) {
                        /*
                        $(
                            'header, .navbar, #hidden-nav-links, .navbar-brand'
                        ).removeClass('eu');
                        $(
                            'header, .navbar, #hidden-nav-links, .navbar-brand'
                        ).addClass('wm');
                        $('.on-login__title > h3').text(
                            'Accedi a Euclidea Wealth Management'
                        );
                        $('.on-login__title > h3').css('color', '#153185');
                        $(
                            '.recupero-password:hover, .recupero-password:active, .recupero-password:focus'
                        ).css('color', '#153185');
                        */
                        loginButton
                            .css('background-color', '#153185')
                            .css('border-color', '#153185');
                        loginPwdLost.hide();
                        // $('.zopim').hide();
                        console.log('isWM');
                    } else {
                        /*
                        $(
                            'header, .navbar, #hidden-nav-links, .navbar-brand'
                        ).removeClass('wm');
                        $(
                            'header, .navbar, #hidden-nav-links, .navbar-brand'
                        ).addClass('eu');
                        $('.on-login__title > h3').text('Accedi a Euclidea');
                        $('.on-login__title > h3').css('color', '#2572b3');
                        $(
                            '.recupero-password:hover, .recupero-password:active, .recupero-password:focus'
                        ).css('color', '#2572b3');
                        */
                        loginButton
                            .css('background-color', '#2572b3')
                            .css('border-color', '#2572b3');
                        loginPwdLost.show();
                        // $('.zopim').show();
                        console.log('isSMART');
                    }
                },
            });
        }
    };

    // Run checkIfIsWealthManagement function as soon as the delay (user stopped typing)
    loginInputName.focusout(function() {
        delay(function() {
            checkIfIsWealthManagement();
        }, 0.1);
    });

    // READY?
    $(document).ready(function() {
        loginForm.parsley(PARSLEY_CONFIG_LOGIN);
        loginForm.submit(function() {
            var p = $(this).parsley();
            p.validate();
            return p.isValid();
        });
    });
} // END CHECK loginForm
